/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Badge, Button, Center, Checkbox, Group, Loader, NumberInput, Select, Text, TextInput } from '@mantine/core';
import { closeAllModals, openModal } from '@mantine/modals';
import { FC, useState } from 'react';
import { i18n } from '@lingui/core';
import { useNavigate } from 'react-router-dom';
import { IconTemplate } from '@tabler/icons';
import {
  ChronoUnit,
  EntityReferenceDTO,
  ExecutionStrategy,
  OccurrenceType,
  PatchWorkflowStepTemplate,
  ProcedureType,
  WorkflowStepTemplateDTO,
  WorkflowTemplateDTO,
} from '../../../Types/LogT';
import { WorkflowS } from '../../../Service/WorkflowS';
import { DataS } from '../../../Service/DataS';
import ActionTypeIcon from '../../../Atoms/ActionTypeIcon';
import WorkflowProcedureItem from '../../../Atoms/Autocomplete/WorkflowProcedureItem';
import { MixpanelS } from '../../../Service/MixpanelS';
import { ConstantS } from '../../../Service/ConstantS';
import ProcedureConfig from './config/ProcedureConfig';

interface DeleteProps {
  template: WorkflowTemplateDTO;
  onDelete: () => void;
  interceptViolation: (httpResponse: Response) => void;
}

export const openDeleteTemplateModal = (props: DeleteProps) =>
  openModal({
    title: (
      <Text weight="bolder" size="xl" align="center" sx={{ lineHeight: 1.2 }}>
        Wirklich löschen?
      </Text>
    ),
    children: <DeleteModal {...props} />,
  });

const DeleteModal: FC<DeleteProps> = ({ template, onDelete, interceptViolation }) => {
  const [loading, setLoading] = useState(false);

  const remove = async () => {
    setLoading(true);
    const removed = await WorkflowS.deleteTemplate(template, interceptViolation);
    if (removed) {
      onDelete();
      closeAllModals();
    }
    setLoading(false);
  };

  return (
    <>
      <Text>Möchten Sie diese Vorlage wirklich löschen?</Text>
      <Group mt="xl" position="right" spacing={5}>
        <Button disabled={loading} color="gray" variant="subtle" onClick={() => closeAllModals()}>
          {i18n._('actions.cancel')}
        </Button>
        <Button
          disabled={loading}
          color="red"
          variant="subtle"
          sx={{ backgroundColor: '#FFEFEF' }}
          rightIcon={loading && <Loader size="xs" />}
          onClick={remove}
        >
          {i18n._('actions.delete')}
        </Button>
      </Group>
    </>
  );
};

interface EditProps {
  workflowStep: WorkflowStepTemplateDTO;
  // eslint-disable-next-line react/no-unused-prop-types
  refresh: () => void;
  availableEntities: EntityReferenceDTO[];
  handleConfigChange?: (cfg: any) => unknown;
  interceptViolation?: (httpResponse: Response) => void;
}

export const openEditStepModal = (props: EditProps) =>
  openModal({
    title: (
      <Text weight="bolder" size="xl" align="center" sx={{ lineHeight: 1.2 }}>
        Workflow-Schritt
      </Text>
    ),
    onClose: props.refresh,
    children: <EditStepModal {...props} />,
  });

interface CreateProps {
  onCreate: (template: WorkflowTemplateDTO) => void;
  existingNames: string[];
}

export const openCreateTemplateModal = (props: CreateProps) =>
  openModal({
    title: (
      <Text weight="bolder" size="xl" align="center" sx={{ lineHeight: 1.2 }}>
        Vorlage hinzufügen
      </Text>
    ),
    children: <CreateTemplateModal {...props} />,
  });

const CreateTemplateModal: FC<CreateProps> = ({ onCreate, existingNames }) => {
  const [name, setName] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const nameTaken = existingNames.includes(name);

  const createTemplate = () => {
    setLoading(true);
    WorkflowS.createTemplate(name)
      .then((template) => {
        if (template) {
          MixpanelS.track(ConstantS.TrackingEvents.WorkflowCreated);
          onCreate(template);
          closeAllModals();
          const { id } = template;
          navigate(`/settings/workflow/templates/${id}`);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <TextInput
        required
        autoComplete="off"
        error={nameTaken && 'Name ist bereits vergeben.'}
        icon={<IconTemplate size={16} />}
        label="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <Center>
        <Button
          sx={{ minWidth: 200 }}
          mt="xl"
          disabled={loading || name === '' || nameTaken}
          rightIcon={loading && <Loader size="xs" />}
          onClick={createTemplate}
        >
          Jetzt erstellen
        </Button>
      </Center>
    </>
  );
};

const EditStepModal: FC<EditProps> = ({ availableEntities, workflowStep, handleConfigChange, interceptViolation }) => {
  const [step, setStep] = useState(workflowStep);
  const { id, procedureType, executionConfig, entityReference, alternativeStep } = step;
  const {
    executionStrategy,
    relativeAmount,
    relativeUnit,
    typeOfInterest,
    entityOfInterest,
    manualApprovalRequired,
    finishWithAlternativeStep,
  } = executionConfig;
  const entities = WorkflowS.filterEntities(availableEntities, [WorkflowS.getOccurrenceType(typeOfInterest)]);
  const isWaitUntil = procedureType === 'WAIT_UNTIL';

  const patchTemplateStep = (stepId: string, patch: Partial<PatchWorkflowStepTemplate>) => {
    if (patch.typeOfInterest === 'MAIL_RECEIVED') {
      const primaryCustomerRef = availableEntities?.find((entity) => entity.entityType === 'contacts');
      if (primaryCustomerRef) {
        patch.entityReferenceId = primaryCustomerRef.id;
      }
    }
    WorkflowS.editWorkflowStep(stepId, patch, interceptViolation).then((resJSON) => {
      if (resJSON) {
        setStep(resJSON);
      }
    });
  };

  return (
    <>
      <Badge mt="md" radius="sm" sx={{ backgroundColor: '#585858', color: 'white' }}>
        <Text weight="lighter"> Aktion</Text>
      </Badge>
      <Select
        searchable
        mt="sm"
        itemComponent={WorkflowProcedureItem}
        placeholder="Aktion auswählen ..."
        icon={procedureType && <ActionTypeIcon actionType={procedureType} />}
        data={DataS.getActionTypeData(i18n, alternativeStep)}
        value={procedureType}
        onChange={(val: ProcedureType) => val && patchTemplateStep(step.id, { procedureType: val })}
        maxDropdownHeight={375}
      />
      {!isWaitUntil && (
        <ProcedureConfig step={step} availableEntities={availableEntities} handleConfigChange={handleConfigChange} />
      )}
      {procedureType && (
        <Badge mt="xl" radius="sm" sx={{ backgroundColor: '#585858', color: 'white' }}>
          <Text weight="lighter"> Ausführung</Text>
        </Badge>
      )}
      {procedureType && procedureType !== 'WAIT_UNTIL' && (
        <>
          <Group spacing={5} mt="md">
            <NumberInput
              hideControls
              disabled={alternativeStep}
              defaultValue={relativeAmount}
              min={0}
              sx={{ width: 50 }}
              onBlur={(e) => patchTemplateStep(id, { relativeAmount: Number(e.currentTarget.value) })}
            />
            <Select
              defaultValue={relativeUnit}
              disabled={alternativeStep}
              data={DataS.getChronoUnitsData(i18n)}
              sx={{ width: 100 }}
              onChange={(val: ChronoUnit) => val && patchTemplateStep(id, { relativeUnit: val })}
            />
            <Select
              sx={{ flexGrow: 1 }}
              disabled={alternativeStep}
              defaultValue={executionStrategy}
              data={DataS.getExecutionStrategyOptions(i18n)}
              onChange={(val: ExecutionStrategy) => val && patchTemplateStep(id, { executionStrategy: val })}
            />
          </Group>
          {procedureType === 'SEND_MAIL' && (
            <>
              <Checkbox
                size="xs"
                mt="sm"
                label="Genehmigung erforderlich"
                defaultChecked={manualApprovalRequired}
                onChange={(e) => patchTemplateStep(id, { manualApprovalRequired: e.target.checked })}
              />
              <Text size="xs" color="dimmed">
                {i18n._('workflow.manual-approval.text')}
              </Text>
            </>
          )}
        </>
      )}
      {procedureType && procedureType === 'WAIT_UNTIL' && (
        <>
          <Select
            mt="md"
            defaultValue={typeOfInterest}
            label="Ereignis"
            data={DataS.getObservableEventOptions(i18n)}
            onChange={(val: OccurrenceType) => val && patchTemplateStep(id, { typeOfInterest: val })}
          />
          {typeOfInterest && (
            <Select
              mt="xs"
              value={entityOfInterest ? entityOfInterest.id : null}
              label={i18n._(`entity.${typeOfInterest}`)}
              data={DataS.getEntityReferencesOptions(entities)}
              onChange={(val) => val && patchTemplateStep(id, { entityReferenceId: val })}
            />
          )}
          <Group spacing={5} mt="xs" position="apart">
            <Text>Warte maximal: </Text>
            <Group spacing={5}>
              <NumberInput
                hideControls
                defaultValue={relativeAmount}
                min={0}
                sx={{ width: 50 }}
                onBlur={(e) => patchTemplateStep(id, { relativeAmount: Number(e.currentTarget.value) })}
              />
              <Select
                defaultValue={relativeUnit}
                data={DataS.getChronoUnitsData(i18n)}
                sx={{ width: 100 }}
                onChange={(val: ChronoUnit) => val && patchTemplateStep(id, { relativeUnit: val })}
              />
            </Group>
          </Group>
          <Checkbox
            mt="sm"
            checked={finishWithAlternativeStep}
            onChange={() => patchTemplateStep(id, { finishWithAlternativeStep: !finishWithAlternativeStep })}
            label="Workflow nach Ablauf der Zeit abbrechen"
          />
          <Text size="xs" color="dimmed">
            Bei Aktivierung, wird nach Ablauf der oben angegebenen Zeit nur noch der Alternativ-Schritt ausgeführt.
            Selbst wenn der Kunde danach reagiert, wird der Workflow NICHT weitergeführt.
          </Text>
          <Text size="xs" color="dimmed">
            Deaktiviere diese Option, wenn du möchtest, dass der Workflow (nach Ausführung vom Alternativ-Schritt)
            weitere 30 Tage auf die Reaktion Deines Kunden warten soll.
          </Text>
        </>
      )}
      {entityReference && (
        <>
          <Badge mt="xl" radius="sm" sx={{ backgroundColor: '#585858', color: 'white' }}>
            <Text weight="lighter"> Interne Workflow-Beschriftung</Text>
          </Badge>
          <TextInput
            mt="md"
            label="Beschriftung"
            defaultValue={entityReference.label}
            onBlur={(e) => WorkflowS.patchEntityReference(entityReference.id, { label: e.currentTarget.value })}
          />
          <Text mt="xs" size="xs" color="dimmed">
            {i18n._('workflow.entityReference.tip-text')}
          </Text>
        </>
      )}
      <Center mt="xl">
        <Button mt="xl" sx={{ minWidth: 200 }} onClick={() => closeAllModals()} disabled={!procedureType}>
          {i18n._('actions.save')}
        </Button>
      </Center>
    </>
  );
};
