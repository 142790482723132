import { useEffect } from 'react';
import { Stack } from '@mantine/core';
import PanePaper from '../../../Molecules/PanePaper';
import { RoutingS } from '../../../Service/RoutingS';
import WorkflowTemplates from '../Workflow/WorkflowTemplates';
import RecurringTaskList from './RecurringTaskList';
import Reminder from './Reminder';
import MailNotificationConfig from './MailNotificationConfig';
import BookitupNotificationsConfigPane from './BookitupNotificationsConfigPane';
import WorkflowDepricationAlert from './WorkflowDepricationAlert';

const WorkflowSection = () => {
  useEffect(() => {
    RoutingS.changeTitle('Workflow');
  }, []);

  return (
    <Stack mb={70} sx={{ maxWidth: 650 }}>
      <WorkflowTemplates />
      <BookitupNotificationsConfigPane />
      <PanePaper title="Workflow" tooltip="help.settings.workflow.pane">
        <WorkflowDepricationAlert />
        <div style={{ marginBottom: 20 }}>
          <div className="description">Hier kannst Du Aufgaben verwalten, die automatisch erstellt werden sollen.</div>
        </div>
        <Reminder />
        <RecurringTaskList />
        <MailNotificationConfig />
      </PanePaper>
    </Stack>
  );
};

export default WorkflowSection;
