import { useEffect, useState } from 'react';
import { fetchRecurringTasks } from '../../../Service/restapi/taskService';
import RecurringTask from './RecurringTask';

export default function () {
  const [recurringTasks, setRecurringTasks] = useState([]);
  const { length } = recurringTasks;

  useEffect(() => {
    loadTasks();
  }, []);

  function loadTasks() {
    fetchRecurringTasks().then((resp) => {
      if (resp.ok) {
        resp.json().then((tasks) => {
          setRecurringTasks(tasks);
        });
      }
    });
  }

  function onChange(patch) {
    const oldRTasks = Object.assign([], recurringTasks);
    const oldTask = oldRTasks.find((a) => a.id === patch.id);
    const indexOf = oldRTasks.indexOf(oldTask);
    oldRTasks.splice(indexOf, 1, patch);
    setRecurringTasks(oldRTasks);
  }

  if (length === 0) {
    return null;
  }

  return (
    <>
      <div className="heading">Wiederkehrende Aufgaben</div>
      <div className="description">
        Hinterlege hier Deinen persönlichen Workflow - Deine Aufgaben, die immer wieder anfallen.
      </div>
      <div>
        {recurringTasks.map((task, index) => (
          <RecurringTask key={index} index={index} task={task} onChange={onChange} reload={loadTasks} />
        ))}
      </div>
    </>
  );
}
