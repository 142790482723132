// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Alert, Text } from '@mantine/core';
import { IconAlertTriangle } from '@tabler/icons';

const WorkflowDepricationAlert = () => (
  <Alert
    icon={<IconAlertTriangle size={16} />}
    title="Diese Einstellungen sind veraltet"
    sx={(theme) => ({ backgroundColor: theme.colors.lightBlue[0] })}
    mb="sm"
  >
    <Text>
      Die Einstellungen in diesem Bereich sind veraltet. Alle Workflows können nun in Workflow-Vorlagen eingestellt
      werden.
    </Text>
  </Alert>
);

export default WorkflowDepricationAlert;
