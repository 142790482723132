/* eslint-disable no-nested-ternary */
import { Divider, Grid, NumberInput, Select, Stack, Text, TextInput } from '@mantine/core';
import { useContext } from 'react';
import { IconAlignLeft, IconBorderRadius, IconPhoto } from '@tabler/icons';
import { BookitupFormContext } from '../../../Service/Context/BookitupFormContext';
import MantineColorPicker from '../../../Atoms/inputs/MantineColorPicker';
import { WindowContext } from '../../../Service/Context/WindowContext';
import { DataS } from '../../../Service/DataS';
import GradientPicker from '../../../Atoms/inputs/GradientPicker';

const ContactFormStyleOptions = () => {
  const { bookitupForm, bookitupFormStyles, updateFormStyles } = useContext(BookitupFormContext);
  const { xs, md } = useContext(WindowContext);
  const gridColSpanSize = xs ? 12 : md ? 6 : 4;

  if (!bookitupForm) {
    return null;
  }

  const {
    formBackgroundColor,
    backgroundImage,
    formTextColor,
    fieldBackgroundColor,
    fieldTextColor,
    fieldBorderColor,
    fieldBorderFocusColor,
    submitButtonBackgroundColor,
    submitButtonTextColor,
    fieldAsteriskColor,
    fieldBorderRadius,
    submitButtonBorderRadius,
    submitButtonPosition,
  } = bookitupFormStyles;

  return (
    <>
      <TextInput
        mb="xl"
        label="Hintergrundbild"
        placeholder="http://domain.de/image-url.png"
        defaultValue={backgroundImage ?? undefined}
        onBlur={(e) => updateFormStyles({ backgroundImage: e.currentTarget.value })}
        icon={<IconPhoto size={20} />}
        sx={{ maxWidth: 600 }}
      />
      <Divider my="xs" label={<Text size="md">Formular</Text>} />
      <Grid mt="md" sx={{ alignItems: 'flex-start' }}>
        <Grid.Col span={gridColSpanSize}>
          <GradientPicker
            initialGradient={formBackgroundColor}
            label="Hintergrundfarbe"
            onChange={(val) => updateFormStyles({ formBackgroundColor: val })}
          />
        </Grid.Col>
        <Grid.Col span={gridColSpanSize}>
          <MantineColorPicker
            label="Textfarbe"
            color={formTextColor}
            onChange={(val) => updateFormStyles({ formTextColor: val })}
          />
        </Grid.Col>
      </Grid>
      <Divider my="xl" label={<Text size="md">Feld</Text>} />

      <NumberInput
        hideControls
        label=" Rundung der Eingabefelder"
        min={0}
        max={50}
        icon={<IconBorderRadius size={20} />}
        value={Number(fieldBorderRadius)}
        onBlur={(e) => e.currentTarget.value !== '' && updateFormStyles({ fieldBorderRadius: e.currentTarget.value })}
        rightSection={
          <Text size="xs" weight="bolder">
            px
          </Text>
        }
        sx={{ maxWidth: 600 }}
      />
      <Grid mt="md" sx={{ alignItems: 'flex-start' }}>
        <Grid.Col span={gridColSpanSize}>
          <GradientPicker
            initialGradient={fieldBackgroundColor}
            label="Hintergrundfarbe"
            onChange={(val) => updateFormStyles({ fieldBackgroundColor: val })}
          />
        </Grid.Col>
        <Grid.Col span={gridColSpanSize}>
          <MantineColorPicker
            label="Textfarbe"
            color={fieldTextColor}
            onChange={(val) => updateFormStyles({ fieldTextColor: val })}
          />
        </Grid.Col>
        <Grid.Col span={gridColSpanSize}>
          <MantineColorPicker
            label="Rahmenfarbe"
            color={fieldBorderColor}
            onChange={(val) => updateFormStyles({ fieldBorderColor: val })}
          />
        </Grid.Col>
        <Grid.Col span={gridColSpanSize}>
          <MantineColorPicker
            label="Rahmenfarbe bei Fokus"
            color={fieldBorderFocusColor}
            onChange={(val) => updateFormStyles({ fieldBorderFocusColor: val })}
          />
        </Grid.Col>
        <Grid.Col span={gridColSpanSize}>
          <MantineColorPicker
            label="Sternchenfarbe"
            color={fieldAsteriskColor}
            onChange={(val) => updateFormStyles({ fieldAsteriskColor: val })}
          />
        </Grid.Col>
      </Grid>

      <Divider my="xl" label={<Text size="md">Absendebutton</Text>} />
      <Stack sx={{ maxWidth: 600 }}>
        <Select
          label="Ausrichten"
          icon={<IconAlignLeft size={20} />}
          value={submitButtonPosition}
          onChange={(val) => val && updateFormStyles({ submitButtonPosition: val })}
          data={DataS.buttonPositionOptions}
        />

        <NumberInput
          hideControls
          label="Rundung vom Absendebutton"
          min={0}
          max={50}
          icon={<IconBorderRadius size={20} />}
          value={Number(submitButtonBorderRadius)}
          onBlur={(e) =>
            e.currentTarget.value !== '' && updateFormStyles({ submitButtonBorderRadius: e.currentTarget.value })
          }
          rightSection={
            <Text size="xs" weight="bolder">
              px
            </Text>
          }
        />
      </Stack>
      <Grid mt="md" sx={{ alignItems: 'flex-start' }}>
        <Grid.Col span={gridColSpanSize}>
          <GradientPicker
            initialGradient={submitButtonBackgroundColor}
            label="Hintergrundfarbe"
            onChange={(val) => updateFormStyles({ submitButtonBackgroundColor: val })}
          />
        </Grid.Col>
        <Grid.Col span={gridColSpanSize}>
          <MantineColorPicker
            label="Textfarbe"
            color={submitButtonTextColor}
            onChange={(val) => updateFormStyles({ submitButtonTextColor: val })}
          />
        </Grid.Col>
      </Grid>
    </>
  );
};

export default ContactFormStyleOptions;
